<template>
    <div class="new_menu">
        <div class="head">
            <img src="@/assets/new_img/img02.png" alt="" srcset="">
        </div>
        <div class="con">
            <div class="div1">
                <img src="@/assets/new_img/img07.png" alt="">
            </div>
            <div class="txt1">你不需要做所有的题</div>
            <div class="txt2">“做研究生该做的题”</div>

            <div class="div2">
                <div class="box">
                    <div class="list">
                        <img src="@/assets/new_img/img08.png" alt="" srcset="">
                        <div class="txt">AI选题</div>
                    </div>
                    <div class="list">
                        <img src="@/assets/new_img/img09.png" alt="" srcset="">
                        <div class="txt">知识盘点</div>
                    </div>
                    <div class="list">
                        <img src="@/assets/new_img/img10.png" alt="" srcset="">
                        <div class="txt">实时估分</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btm" v-if="is_first">
            <div class="tab">
                <div class="tab-list" :class="tab_active==1?'on':''" @click="tabClick(1)">数学一</div>
                <div class="tab-list" :class="tab_active==2?'on':''" @click="tabClick(2)">数学二</div>
                <div class="tab-list" :class="tab_active==3?'on':''" @click="tabClick(3)">数学三</div>
                <div class="tab-list" :class="tab_active==4?'on':''" @click="tabClick(4)">不确定</div>
            </div>
            <div class="btn" @click="goTo('/new_topic')">开始训练</div>
        </div>
        <div class="btm" v-else>
            <div class="txt">进度：基础训练 {{ jindu }}%</div>
            <div class="btn" @click="goTo('/new_topic')">继续训练</div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                tab_active: 1,
                is_first: true,
                jindu: 0,
            }
        },
        created () {
            if(localStorage.is_first && localStorage.is_first !=='undefined'){
                this.is_first = false;
            }
            if(localStorage.jindu && localStorage.jindu !=='undefined'){
                this.jindu = +(+localStorage.jindu).toFixed(2) || 0;
            }
            if(localStorage.tab_active && localStorage.tab_active !=='undefined'){
                this.tab_active = localStorage.tab_active;
            }
        },
        methods: {
            tabClick(e) {
                this.tab_active = e;
                localStorage.tab_active = e;
            },
            write_mathtype() {
                let param = {
                    math_type: 's' + this.tab_active
                }
                this.$api.write_mathtype({param}).then(res=>{
                    console.log('res', res)
                })
            },
            goTo(url){
                if(this.tab_active==4){
                    this.$dialog({
                        message: `数一专业：工学门类中的力学、机械、光学、仪器、冶金、动力工程、热物理、电气、电子科学、通信、控制、计算机、土木、水利、测绘、交通运输、船舶海洋、航空、兵器、核科学、生物医学，以及授予工学学位的管理科学

数二专业：纺织、轻工技术、农业工程、林业工程、食品科学

「不在以上范围内的理工科则由招生单位自主确定，比如材料、化工、地质、矿业、石油天然气、环境工程等」

数三专业：经济学、管理学（工商、农林经济），以及授予管理学位的管理科学

『如果还有不清楚，请加老师微信:  wqky02』`,
                    }).then(() => {
                        // on close
                    });
                    return
                }
                this.write_mathtype();
                this.$router.push(url);
                localStorage.is_first = true;
            }
        },
    }
</script>

<style lang="less" scoped>
    .new_menu{
        background: #415FD5;
        min-height: 100vh;
        padding: 20px;
        box-sizing: border-box;
        .head{
            text-align: left;
            img{
                width: 36px;
            }
        }
        .con{
            .div1{
                padding-top: 32px;
                img{
                    width: 56px;
                }
            }
            .txt1{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height */

                text-align: center;

                color: rgba(255, 255, 255, 0.7);
                padding-top: 16px;
            }
            .txt2{
                padding-top: 4px;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                /* identical to box height */

                text-align: center;

                color: #F9C200;

                text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
                padding-bottom: 64px;
            }
            .div2{
                padding: 0 40px;
                .box{
                    display: flex;
                    justify-content: space-between;
                    .list{
                        img{
                            width: 32px;
                        }
                        .txt{
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            /* identical to box height */

                            text-align: center;

                            color: #FFFFFF;
                            padding-top: 12px;
                        }
                    }
                }
            }
        }
        .btm{
            position: fixed;
            bottom: 48px;
            .tab{
                display: flex;
                justify-content: space-between;
                &-list{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height */
                    width: 90px;

                    color: rgba(255, 255, 255, 0.5);
                    position: relative;
                    &.on{
                        font-weight: 600;
                        font-size: 16px;
                        color: #FFFFFF;
                        &::before{
                            content: '';
                            background: #F9C200;
                            border-radius: 4px;
                            width: 19px;
                            height: 3px;
                            position: absolute;
                            bottom: -8px;
                            left: 50%;
                            transform: translateX(-58%);
                        }
                    }
                    &::after{
                        content: '';
                        height: 12px;
                        background: rgba(255, 255, 255, 0.5);
                        width: 1px;
                        position: absolute;
                        right: 0;
                        top: 5px;
                    }
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                }
            }
            .txt{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #F9C200;
            }
            .btn{
                width: 335px;
                height: 48px;
                background: linear-gradient(90.28deg, #E6F7E8 0.24%, #D9DFF8 99.77%);
                border-radius: 32px;

                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 48px;

                color: #000000;
                margin-top: 32px;
            }
        }
    }
</style>